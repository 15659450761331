import {
  ISinglePromoCode,
  getSystemPromoCodesAction,
  IPage,
} from "../../constants";
import { action, payload } from "ts-action";
export const getSystemPromoCode = action(
  getSystemPromoCodesAction.requested,
  payload<IPage>()
);
export const getSystemPromoCodeSuccess = action(
  getSystemPromoCodesAction.fulfilled,
  payload<{ data: ISinglePromoCode[]; promosHasMore: boolean }>()
);
export const getSystemPromoCodeFailed = action(
  getSystemPromoCodesAction.rejected,
  payload<Error>()
);

// export const getSystemPromoCode = (
//   page: constants.IPage
// ): IGetSystemPromoCodes => ({
//   type: constants.getSystemPromoCodesAction.requested,
//   payload: page
// });

// export const getSystemPromoCodeFailed = (
//   data: ISinglePromoCode[]
// ) => ({
//   type: constants.getSystemPromoCodesAction.fulfilled,
//   payload: data
// });

// export const getSystemPromoCodeFailed = (
//   error: Error
// ): IGetSystemPromoCodesFailed => ({
//   type: constants.getSystemPromoCodesAction.rejected,
//   payload: error
// });
