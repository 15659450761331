import React, { useEffect, useMemo } from "react";
import styles from "./styles.module.sass";
import BackIcon from "../../assets/ic_arrow_back.svg";
import PlusIcon from "../../assets/feather_plus.svg";
import DefaultBrandLogo from "../../assets/admin-placeholder-cover.svg";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getBrandDetails,
  getBrandPosEntities,
  getPosProviders,
  setBrandPosEntity,
} from "../../redux-store/actions";
import { useSelect } from "../../hooks/useSelect";
import { prefixImage } from "../../Components/Profile";
import PosIntegrationForm from "./PosIntegrationCard";
import { LoadingStatus } from "../../redux-store/reducers/withLoadingState";
import { BounceLoader } from "react-spinners";
import InfoIcon from "../../assets/info.svg";
import AddIcon from "../../assets/feather _ plus.svg";
export const PosIntegration: React.FC<RouteComponentProps<{
  store_id: string;
}>> = ({ match: { params }, history: { goBack } }) => {
  const dispatch = useDispatch();
  const {
    brand,
    getBrandPosEntitiesReducer: {
      brandPosEntitiesById,
      brandPosEntitiesIds,
      loadingStatus,
    },
  } = useSelect((state) => ({
    brand: state.brandDetailsReducer.BRAND_DETAILS,
    getBrandPosEntitiesReducer: state.getBrandPosEntitiesReducer,
  }));
  useEffect(() => {
    dispatch(getPosProviders());
    if (params.store_id) {
      dispatch(getBrandDetails(params.store_id));
      dispatch(getBrandPosEntities(params.store_id));
    }
  }, [params.store_id]);

  const noBrandPosVendor = useMemo(
    () =>
      loadingStatus &&
      [LoadingStatus.success, LoadingStatus.failed].includes(loadingStatus) &&
      !brandPosEntitiesIds?.length,
    [loadingStatus, brandPosEntitiesIds]
  );
  return (
    <div className={styles.wrapper}>
      <button onClick={goBack} className={styles.backWrapper}>
        <img src={BackIcon} alt="Back" />
        <span className={styles.backText}>Back</span>
      </button>

      <div className={styles.posContainer}>
        <div className={styles.brandInfo}>
          <img
            className={styles.brandLogo}
            src={
              brand?.cover_image
                ? prefixImage(brand.cover_image)
                : DefaultBrandLogo
            }
          />
          <div className={styles.brandTexts}>
            <span className={styles.brandName}>{brand?.name}</span>
            <span className={styles.brandAddress}>{brand?.location}</span>
          </div>
        </div>
        <div
          className={styles.content}
          style={
            loadingStatus === LoadingStatus.loading
              ? { justifyContent: "space-between" }
              : {}
          }
        >
          <span className={styles.posIntegrationText}>POS Integration</span>

          {loadingStatus === LoadingStatus.loading ? (
            <div className={styles.loadingWrapper}>
              <BounceLoader loading={true} size={30} color={"#FEBE19"} />
            </div>
          ) : noBrandPosVendor ? (
            <div className={styles.noPosWrapper}>
              <img className={styles.infoIcon} src={InfoIcon} />
              <span className={styles.noPosText}>
                No POS integrations have been added yet. Let's
                <br /> begin by adding the first one now!
              </span>
            </div>
          ) : (
            <React.Fragment>
              {(brandPosEntitiesIds || [])?.map((element, index) => (
                <PosIntegrationForm
                  cancelChanges={() => {
                    dispatch(
                      setBrandPosEntity({ id: element, mode: undefined })
                    );
                  }}
                  store_id={params.store_id}
                  key={index}
                  brandPosEntity={brandPosEntitiesById[element]}
                />
              ))}
            </React.Fragment>
          )}

          <button
            onClick={() => {
              dispatch(
                setBrandPosEntity({
                  api_key: "",
                  created_at: 0,
                  id: "",
                  name: "",
                  pos_vendor: "none",
                  updated_at: 0,
                  mode: "create",
                })
              );
            }}
            className={`${
              noBrandPosVendor ? styles.noBrandPosBtn : styles.addNewPos
            }`}
          >
            <img
              className={styles.addIcon}
              src={noBrandPosVendor ? AddIcon : PlusIcon}
            />
            Add new Pos
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PosIntegration);
