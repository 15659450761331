import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getSystemPromoCodesAction,
  // IGetSystemPromoCodesActionTypes
} from "../../constants";
import { selectToken } from "../selectors";
import { getSystemPromoCodesAPI } from "../../axios/get-system-promo-codes";
import {
  getSystemPromoCodeSuccess,
  getSystemPromoCodeFailed,
  getSystemPromoCode,
} from "../actions/getSystemPromoCodesActions";
import { union } from "ts-action";
const actionType = union({ getSystemPromoCode } as any);
function* getSystemPromoCodesSaga({ payload }: typeof actionType) {
  try {
    const { page, per_page, filters } = payload;
    const token = yield select(selectToken);
    const res = yield call(
      getSystemPromoCodesAPI,
      token,
      page,
      per_page,
      filters
    );
    yield put(
      getSystemPromoCodeSuccess({
        data: res.data ?? [],
        promosHasMore: (res.data ?? []).length >= 15,
      })
    );
  } catch (e) {
    yield put(getSystemPromoCodeFailed(e));
  }
}

export function* watchSystemGetPromoCodesSaga() {
  yield takeLatest(
    getSystemPromoCodesAction.requested,
    getSystemPromoCodesSaga
  );
}
