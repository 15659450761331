import * as constants from "../../constants";
import { ISingleCountryStore } from "../../axios/getBrands";
import {
  IGetCountryStoresRequestedAction,
  IGetCountryStoresFulfilledAction,
  IGetCountryStoresRejectedAction,
} from "../../constants";

export const getCountryStores = (
  payload: any
): IGetCountryStoresRequestedAction => ({
  type: constants.getCountryStoresAction.requested,
  payload,
});

export const getCountryStoresSuccess = (
  stores: ISingleCountryStore[]
): IGetCountryStoresFulfilledAction => ({
  type: constants.getCountryStoresAction.fulfilled,
  payload: stores,
});

export const getCountryStoresFailure = (
  error: Error
): IGetCountryStoresRejectedAction => ({
  type: constants.getCountryStoresAction.rejected,
  payload: error,
});
