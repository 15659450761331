import * as constants from "../constants";
import {
  ACTION_DISABLE_LOAD_MORE,
  ACTION_ENABLE_LOAD_MORE,
  ACTION_FILL_EDIT_BRAND,
  ACTION_FILTER_BRANDS,
  ACTION_RESET_EDIT_BRANDS,
  ACTION_RESET_FILTER_BRANDS,
  addBrandAction,
  editBrandAction,
  getBrandDetailsAction,
  getBrandWizardInfoAction,
} from "../constants";
import { IStore, IStoresFilters, RoutingMethods } from "../axios/getBrands";
import { IStatsFilters } from "../axios/types";
import { IPromotedBrandsSectionTitle } from "src/redux-store/actions/promotedSectionTitleAction";

// import {ISelectItemData} from "../Components/FormElements/RenderDropDownSelect";
export interface IUser {
  email: string;
  name: string;
  id: string;
  country_iso_code: string;
  role: string;
}

export interface IAddWatchers {
  email: string;
  name: string;
  id: string;
  country_iso_code: string;
  role: string;
}

export interface IStoreStatsData {
  activation_date: number;
  converting_ratio: number;
  created_at: number;
  payment_date: number;
  payment_term: number;
  starting_branches_count: number;
  status: number;
  today_visits: number;
  total_customers_number: number;
  total_visits: number;
  updated_at: number;
  country_code: string;
  country_iso_code: string;
  email: string;
  id: string;
  manager_id: string;
  brand_name: string;
  password: string;
  phone_number: string;
  user_name: string;
  routing_method: RoutingMethods;
  pos_ordering?: string;
}

export interface IListenToNetworkChangeAction {
  type: string;
}

export interface ILoginResponse {
  token: string;
}

export interface ILoginAction {
  type: typeof constants.loginAction.requested;
  payload: {
    email: string;
    password: string;
  };
}

export interface ILoginSuccessAction {
  type: typeof constants.loginAction.fulfilled;
  payload: {
    token: string;
  };
}

export interface ILoginFailureAction {
  type: typeof constants.loginAction.fulfilled;
  payload: Error;
}

export type IAuthAction =
  | ILoginAction
  | ILoginSuccessAction
  | ILoginFailureAction;

export interface ICountry {
  EnName: string;
  ArName: string;
  country_code: string;
  ISO_code: string;
  icon: string;
}

export interface IPaymentLogs {
  created_at: number;
  updated_at: number;
  comment: string;
  type: string;
  store_id: string;
  store_name: string;
}

export interface IGetCountriesAction {
  type: typeof constants.getCountriesAction.requested;
  payload: { search?: string; page?: number };
}

export interface IGetCountriesAction {
  type: typeof constants.getCountriesAction.requested;
  payload: { search?: string; page?: number };
}

export interface IGetCountriesSuccessAction {
  type: typeof constants.getCountriesAction.fulfilled;
  payload: ICountry[];
}

export interface IGetCountriesFailureAction {
  type: typeof constants.getCountriesAction.rejected;
  payload: Error;
}

export interface ILogoutAction {
  type: typeof constants.logoutAction.requested;
}

export interface ILogoutSuccessAction {
  type: typeof constants.logoutAction.fulfilled;
}

export interface ILogoutFailureAction {
  type: typeof constants.logoutAction.rejected;
  payload: Error;
}

export interface IDashboardPreviewAction {
  type: typeof constants.dashboardPreviewLoggerAction.requested;
  payload: {
    store_id: string;
  };
}

export interface IDashboardPreviewActionSuccess {
  type: typeof constants.dashboardPreviewLoggerAction.fulfilled;
}

export interface IDashboardPreviewActionError {
  type: typeof constants.dashboardPreviewLoggerAction.rejected;
  payload: Error;
}

export interface IGetBrandsAction {
  type: typeof constants.getBrandsAction.requested;
  payload: IStoresFilters;
}

export interface IGetBrandsResponse {
  brands: IStore[];
}

export interface IGetBrandsSuccessAction {
  type: typeof constants.getBrandsAction.fulfilled;
  payload: IStore[];
}

export interface IGetBrandsStatisticsSuccessAction {
  type: typeof constants.ACTION_GET_BRANDS_STATISTICS_SUCCESS;
  payload: IStore[];
}

export interface IGetBrandsFailureAction {
  type: typeof constants.getBrandsAction.rejected;
  payload: Error;
}

export type getBrandsAction =
  | IGetBrandsAction
  | IGetBrandsSuccessAction
  | IGetBrandsFailureAction;

// promoted brands actions

export interface IGetPromotedBrandsAction {
  type: typeof constants.getPromotedBrandsAction.requested;
  payload: IStoresFilters;
}

export interface IGetPromotedBrandsResponse {
  brands: IStore[];
}

export interface IGetPromotedBrandsSuccessAction {
  type: typeof constants.getPromotedBrandsAction.fulfilled;
  payload: IStore[];
}

export interface IGetPromotedBrandsStatisticsSuccessAction {
  type: typeof constants.ACTION_GET_PROMOTED_BRANDS_STATISTICS_SUCCESS;
  payload: IStore[];
}

export interface IGetPromotedBrandsFailureAction {
  type: typeof constants.getPromotedBrandsAction.rejected;
  payload: Error;
}

export type getPromotedBrandsAction =
  | IGetPromotedBrandsAction
  | IGetPromotedBrandsSuccessAction
  | IGetPromotedBrandsFailureAction;

// edit

export interface IEditPromotedBrandAction {
  type: typeof constants.editPromotedBrandAction.requested;
}

export interface IEditPromotedBrandSuccessAction {
  type: typeof constants.editPromotedBrandAction.fulfilled;
  payload: { store: IStore; promoted_status: "add" | "remove" };
}

export interface IEditPromotedBrandMutate {
  type: typeof constants.ACTION_EDIT_PROMOTED_BRAND_MUTATION_SUCCESS;
  payload: any;
}

export interface IEditPromotedBrandFailureAction {
  type: typeof constants.editPromotedBrandAction.rejected;
  payload: Error;
}

export type editPromotedBrandActionType =
  | IEditPromotedBrandAction
  | IEditPromotedBrandSuccessAction
  | IEditPromotedBrandFailureAction;

// promoted brands actions

export interface IPromotedSectionTitle {
  ar_title: string;
  en_title: string;
}
// promoted section Title
export interface IGetPromotedSectionTitleAction {
  type: typeof constants.getPromotedBrandsTitleAction.requested;
}

export interface IGetPromotedSectionTitleResponse {
  title: IPromotedSectionTitle;
}

export interface IGetPromotedSectionTitleSuccessAction {
  type: typeof constants.getPromotedBrandsTitleAction.fulfilled;
  payload: IPromotedSectionTitle;
}

export interface IGetPromotedSectionTitleFailureAction {
  type: typeof constants.getPromotedBrandsTitleAction.rejected;
  payload: Error;
}

export type getPromotedBrandsTitleAction =
  | IGetPromotedSectionTitleAction
  | IGetPromotedSectionTitleSuccessAction
  | IGetPromotedSectionTitleFailureAction;

// edit

export interface IEditPromotedSectionTitleAction {
  type: typeof constants.editPromotedBrandAction.requested;
}

export interface IEditPromotedSectionTitleSuccessAction {
  type: typeof constants.editPromotedBrandAction.fulfilled;
  payload: IPromotedBrandsSectionTitle;
}

export interface IEditPromotedSectionTitleFailureAction {
  type: typeof constants.editPromotedBrandAction.rejected;
  payload: Error;
}

export type editPromotedSectionTitleActionType =
  | IEditPromotedSectionTitleAction
  | IEditPromotedSectionTitleSuccessAction
  | IEditPromotedSectionTitleFailureAction;

// promoted brands actions

export interface IStep {
  created_at: number;
  end_time: number;
  start_time: number;
  id: string;
  updated_at: number;
  key: number;
}

export interface IFilterBrandsAction {
  type: typeof ACTION_FILTER_BRANDS;
  payload: {
    name: string;
    country: string;
    payment: string;
  };
}

export interface IResetFilterBrandsAction {
  type: typeof ACTION_RESET_FILTER_BRANDS;
}

export interface ILoadMoreBrandsAction {
  type: typeof ACTION_ENABLE_LOAD_MORE;
}

export interface IResetLoadMoreBrandsAction {
  type: typeof ACTION_DISABLE_LOAD_MORE;
}

export interface ICountriesReducerState {
  countries: ICountry[];
}

export interface IGetBrandDetailsAction {
  type: typeof getBrandDetailsAction.requested;
  payload: {
    store_id: string;
  };
}

// exporting brand's foodics menu
export interface IExportFoodicsMenuAction {
  type: typeof constants.exportFoodicsMenuAction.requested;
  payload: {
    api_key: string;
  };
}

export interface IGetBrandDetailsSuccessAction {
  type: typeof getBrandDetailsAction.fulfilled;
  payload: IStoreStatsData[];
}

export interface IGetBrandDetailsFailureAction {
  type: typeof getBrandDetailsAction.rejected;
  payload: {
    error: Error;
  };
}

export interface IFillEditBrandAction {
  type: typeof ACTION_FILL_EDIT_BRAND;
  payload: IStore;
}

export type getBrandDetailsAction =
  | IGetBrandsAction
  | IGetBrandsSuccessAction
  | IGetBrandsFailureAction;

export interface IServerStuff {
  id: never;
  created_at: never;
  updated_at: never;
}

export interface IResetEditBrandAction {
  type: typeof ACTION_RESET_EDIT_BRANDS;
}

export interface IEditBrandAction {
  type: typeof editBrandAction.requested;
}

export interface IEditBrandSuccessAction {
  type: typeof editBrandAction.fulfilled;
  payload: IStore;
}

export interface IEditBrandMutate {
  type: typeof constants.ACTION_EDIT_BRAND_MUTATION_SUCCESS;
  payload: any;
}

export interface IEditBrandFailureAction {
  type: typeof editBrandAction.rejected;
  payload: Error;
}

export type editBrandActionType =
  | IEditBrandAction
  | IEditBrandSuccessAction
  | IEditBrandFailureAction;

export interface IAddBrandAction {
  type: typeof addBrandAction.requested;
  payload: boolean;
}

export interface IAddBrandSuccessAction {
  type: typeof addBrandAction.fulfilled;
  payload: any;
}

export interface IAddBrandFailureAction {
  type: typeof addBrandAction.rejected;
  payload: Error;
}

export type addBrandActionType =
  | IAddBrandAction
  | IAddBrandSuccessAction
  | IAddBrandFailureAction;

export type appLanguages = "ar" | "en";

export interface IGetBrandFailedOperationsNumberAction {
  type: typeof constants.getBrandFailedOperationsNumberAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandFailedOperationsNumberSuccessAction {
  type: typeof constants.getBrandFailedOperationsNumberAction.fulfilled;
  payload: number;
}

export interface IGetBrandFailedOperationsNumberFailureAction {
  type: typeof constants.getBrandFailedOperationsNumberAction.rejected;
  payload: Error;
}

export interface IGetBrandRegisteredCustomerNumberAction {
  type: typeof constants.getBrandRegisteredCustomerNumberAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandRegisteredCustomerNumberSuccessAction {
  type: typeof constants.getBrandRegisteredCustomerNumberAction.fulfilled;
  payload: number;
}

export interface IGetBrandRegisteredCustomerNumberFailureAction {
  type: typeof constants.getBrandRegisteredCustomerNumberAction.rejected;
  payload: Error;
}

export interface IGetBrandReviewsNumberAction {
  type: typeof constants.getBrandReviewsNumberAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandReviewsNumberSuccessAction {
  type: typeof constants.getBrandReviewsNumberAction.fulfilled;
  payload: number;
}

export interface IGetBrandReviewsNumberFailureAction {
  type: typeof constants.getBrandReviewsNumberAction.rejected;
  payload: Error;
}

export interface IGetBrandConversionRateAction {
  type: typeof constants.getBrandConversionRateAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandConversionRateSuccessAction {
  type: typeof constants.getBrandConversionRateAction.fulfilled;
  payload: number;
}

export interface IGetBrandConversionRateFailureAction {
  type: typeof constants.getBrandConversionRateAction.rejected;
  payload: Error;
}

export interface IGetBrandRedemptionRateAction {
  type: typeof constants.getBrandRedemptionRateAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandRedemptionRateSuccessAction {
  type: typeof constants.getBrandRedemptionRateAction.fulfilled;
  payload: number;
}

export interface IGetBrandRedemptionRateFailureAction {
  type: typeof constants.getBrandRedemptionRateAction.rejected;
  payload: Error;
}

export interface IGetBrandRedemptionNumberAction {
  type: typeof constants.getBrandRedemptionNumberAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandRedemptionNumberSuccessAction {
  type: typeof constants.getBrandRedemptionNumberAction.fulfilled;
  payload: number;
}

export interface IGetBrandRedemptionNumberFailureAction {
  type: typeof constants.getBrandRedemptionNumberAction.rejected;
  payload: Error;
}

export interface IGetBrandTempCustomersNumberAction {
  type: typeof constants.getBrandTempCustomersNumberAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandTempCustomersNumberSuccessAction {
  type: typeof constants.getBrandTempCustomersNumberAction.fulfilled;
  payload: number;
}

export interface IGetBrandTempCustomersNumberFailureAction {
  type: typeof constants.getBrandTempCustomersNumberAction.rejected;
  payload: Error;
}

export interface IGetBrandVisitsNumberAction {
  type: typeof constants.getBrandVisitsNumberAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandVisitsNumberSuccessAction {
  type: typeof constants.getBrandVisitsNumberAction.fulfilled;
  payload: number;
}

export interface IGetBrandVisitsNumberFailureAction {
  type: typeof constants.getBrandVisitsNumberAction.rejected;
  payload: Error;
}

export interface IGetBrandUnresolvedIssuesAction {
  type: typeof constants.getBrandUnresolvedIssuesNumberAction.requested;
  payload: {
    store_id: string;
    filters: IStatsFilters;
  };
}

export interface IGetBrandUnresolvedIssuesSuccessAction {
  type: typeof constants.getBrandUnresolvedIssuesNumberAction.fulfilled;
  payload: number;
}

export interface IGetBrandUnresolvedIssuesFailureAction {
  type: typeof constants.getBrandUnresolvedIssuesNumberAction.rejected;
  payload: Error;
}

export type getFailedOperationsNumberActionType =
  | IGetBrandFailedOperationsNumberAction
  | IGetBrandFailedOperationsNumberFailureAction
  | IGetBrandFailedOperationsNumberSuccessAction;
export type getBrandRegisteredCustomerNumberActionType =
  | IGetBrandRegisteredCustomerNumberAction
  | IGetBrandRegisteredCustomerNumberSuccessAction
  | IGetBrandRegisteredCustomerNumberFailureAction;
export type getBrandReviewsNumberActionType =
  | IGetBrandReviewsNumberAction
  | IGetBrandReviewsNumberSuccessAction
  | IGetBrandReviewsNumberFailureAction;
export type getBrandConversionRateActionType =
  | IGetBrandConversionRateAction
  | IGetBrandConversionRateSuccessAction
  | IGetBrandConversionRateFailureAction;
export type getBrandRedemptionRateActionType =
  | IGetBrandRedemptionRateAction
  | IGetBrandRedemptionRateSuccessAction
  | IGetBrandRedemptionRateFailureAction;
export type getBrandRedemptionNumberActionType =
  | IGetBrandRedemptionNumberAction
  | IGetBrandRedemptionNumberSuccessAction
  | IGetBrandRedemptionNumberFailureAction;
export type getBrandTempCustomersNumberActionType =
  | IGetBrandTempCustomersNumberAction
  | IGetBrandTempCustomersNumberSuccessAction
  | IGetBrandTempCustomersNumberFailureAction;
export type getBrandVisitsNumberActionType =
  | IGetBrandVisitsNumberAction
  | IGetBrandVisitsNumberSuccessAction
  | IGetBrandVisitsNumberFailureAction;
export type getBrandUnresolvedIssuesActionType =
  | IGetBrandUnresolvedIssuesAction
  | IGetBrandUnresolvedIssuesSuccessAction
  | IGetBrandUnresolvedIssuesFailureAction;

export interface IVerifyPaymentAction {
  type: typeof constants.verifyPaymentAction.requested;
  payload: string;
}

export interface IVerifyPaymentSuccessAction {
  type: typeof constants.verifyPaymentAction.fulfilled;
  payload: number;
}

export interface IVerifyPaymentFailureAction {
  type: typeof constants.verifyPaymentAction.rejected;
  payload: Error;
}

export interface IConfirmPaymentAction {
  type: typeof constants.confirmPaymentAction.requested;
  payload: string;
}

export interface IConfirmPaymentSuccessAction {
  type: typeof constants.confirmPaymentAction.fulfilled;
}

export interface IConfirmPaymentFailureAction {
  type: typeof constants.confirmPaymentAction.rejected;
  payload: Error;
}

export interface IResetPaymentAction {
  type: typeof constants.ACTION_RESET_PAYMENT;
}

export interface IGetPaymentLogsAction {
  type: typeof constants.getPaymentLogsAction.requested;
  payload: string;
}

export interface IGetPaymentLogsSuccessAction {
  type: typeof constants.getPaymentLogsAction.fulfilled;
  payload: IPaymentLogs[];
}

export interface IGetPaymentLogsFailureAction {
  type: typeof constants.getPaymentLogsAction.rejected;
  payload: Error;
}

export interface IResetPaymentLogsAction {
  type: typeof constants.ACTION_RESET_PAYMENT_LOGS;
}

export interface IGetBrandWizardInfoAction {
  type: typeof getBrandWizardInfoAction.requested;
  payload: string;
}

export interface IGetBrandWizardInfoSuccessAction {
  type: typeof getBrandWizardInfoAction.fulfilled;
  payload: {
    currentStep: IStep;
    wizardToken: string;
  };
}

export type PaymentStatus =
  | "on_setup"
  | "on_trial"
  | "paid"
  | "waiting_for_payment";
export type MerchantStatus = "inactive" | "running" | "in_progress" | "closed";
export type Country = "eg" | "sa" | "in";
export type Activity = "low_active" | "active" | "inactive";
export interface IGetBrandWizardInfoFailureAction {
  type: typeof getBrandWizardInfoAction.rejected;
  payload: Error;
}

export interface IAccountManagerInfo {
  name: string;
  email: string;
}

// account managers
export * from "./account_managers.types";