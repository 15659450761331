import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { ISelectItemData } from "../Components/FormElements/RenderDropDownSelect";

export enum RoutingMethods {
  call_center = "call_center",
  standalone = "standalone",
  pos = "pos",
}
export interface IStore {
  promoted_at?: number;
  operating_start_date?: any;
  account_type?: string;
  api_key: string;
  active_deals?: string[];
  phone_number: string;
  branches_count: number;
  background_color: string;
  bio: string;
  converting_ratio: number;
  country_code: string;
  has_wizard: boolean;
  country_iso_code: string;
  cover_image: string;
  expiration_period: number;
  last_3days_visits: number;
  facebook_link: string;
  id: string;
  manager_id: string;
  instagram_link: string;
  location: string;
  name: string;
  status: number;
  email: string;
  payment_status: number;
  store_image: string;
  updated_at: number;
  activation_date: number;
  total_customers_number: number;
  total_visits: number;
  payment_date: any;
  payment_term: number;
  pos: string;
  ordering_access_token: string;
  pos_ordering_enabled: number;
  en_name: string;
  ar_name: string;
  routing_method: RoutingMethods;
  pos_ordering?: string;
}

export interface ISingleCountryStore {
  label: string;
  value: string;
  img: string;
}

export interface IStores {
  stores: IStore[];
}

export interface IAddStoreInfo {
  name;
  username;
  email;
  password;
  phone_number;
  country_iso_code;
  payment_term;
  payment_date;
  activation_date;
  starting_branches_count;
}

export interface IStoresFilters {
  group: ISelectItemData;
}

export const getBrands = (
  token,
  filters: IStoresFilters
): AxiosReturn<IStore[]> => {
  return axios.get(Requests.getBrands, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
    params: {
      group: filters.group.value === -1 ? undefined : filters.group.value,
    },
  });
};
