import React, { useCallback, useEffect } from "react";
import styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import SettingsSwitch from "../../../../../../Components/ShopXSettings/SettingsSwitch";
import { ReactSwitchProps } from "react-switch";
import { activeMerchantDealsAtom } from "../../../../../../atoms/merchantProfile";
import { useRecoilValue } from "recoil";
import ReactTooltip from "react-tooltip";
import FleetStatusToggle from "../../../PickupStatusToggle/FleetStatusToggle";
import { useSelect } from "../../../../../../hooks/useSelect";
import { useDeliveryMethod } from "../../../PickupStatusToggle/FleetStatusToggle/hooks";
import { DeliveryManagementStatus } from "../../../../../../axios/editFleetStatus";

interface IProps {
  open: boolean;
  store_id: string;
  onClose: () => void;
  pickupToggle: (checked: any) => void;
  deliveryToggle: (checked: any) => void;
  loyaltyToggle: (checked: any) => void;
  portalToggle: (checked: any) => void;
  qrPortalToggle: (checked: any) => void;
}

interface IActiveDealProps extends ReactSwitchProps {
  title: string;
  tooltip?: string;
}
const ActiveDeal: React.FC<IActiveDealProps> = ({ title, ...props }) => {
  return (
    <div className={styles.activeDeal}>
      <span className={styles.activeDealTitle}>{title}</span>
      {props.disabled && typeof props.tooltip === "string" && (
        <ReactTooltip
          id={`id${props.id}`}
          place={"top"}
          className={styles.tooltipWrapper}
        >
          <div>{props.tooltip}</div>
        </ReactTooltip>
      )}
      <div data-tip={true} data-for={`id${props.id}`}>
        <SettingsSwitch
          onColor={"#ffa351"}
          handleDiameter={28}
          offColor={"#e5e5ea"}
          onHandleColor="#ffffff"
          offHandleColor="#8e8e93"
          height={32}
          width={56}
          {...props}
        />
      </div>
    </div>
  );
};

export const EditActiveMerchantDeals: React.FC<IProps> = ({
  open,
  store_id,
  onClose,
  pickupToggle,
  deliveryToggle,
  loyaltyToggle,
  portalToggle,
  qrPortalToggle,
}) => {
  const { token } = useSelect(state => state.authReducer)
  const { deliveryManagementStatusSwitch } = useDeliveryMethod(token, store_id)
  const merchantDeals = useRecoilValue(activeMerchantDealsAtom);
  const checked = useCallback(
    (type) =>
      merchantDeals[type]?.status === "service_enabled" ||
        merchantDeals[type]?.status === "service_enabled_unpublished" ||
        merchantDeals[type]?.status === "service_enabled_published"
        ? true
        : false,
    [merchantDeals]
  );
  useEffect(() => {
    if (merchantDeals.delivery_status.status === "service_disabled" && merchantDeals.delivery_status.loading === "success") {
      deliveryManagementStatusSwitch(DeliveryManagementStatus.disabled)
    }
  }, [merchantDeals.delivery_status.status, merchantDeals.delivery_status.loading])
  return (
    <Modal open={open} onClose={onClose} className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span className={styles.title}>Edit active deal type</span>
          <div onClick={onClose} className={styles.closeWrapper}>
            <img
              className={styles.close}
              src={require("../../../../../../assets/feather _ cross.svg")}
            />
          </div>
        </div>

        <ActiveDeal
          onChange={loyaltyToggle}
          id="loyalty-system"
          disabled={merchantDeals.loyalty_status.loading === "loading"}
          checked={checked("loyalty_status")}
          title="Loyalty system"
        />
        <ActiveDeal
          onChange={pickupToggle}
          id={"pickup"}
          disabled={merchantDeals.pickup_status.loading === "loading"}
          checked={checked("pickup_status")}
          title="Pickup"
        />
        <ActiveDeal
          onChange={deliveryToggle}
          id={"delivery"}
          disabled={merchantDeals.delivery_status.loading === "loading"}
          checked={checked("delivery_status")}
          title="Delivery"
        />
        {
          merchantDeals.delivery_status.status === "service_enabled" && (
            <div className={styles.fleetWrapper}>
              <FleetStatusToggle store_id={store_id} token={token} />
            </div>
          )
        }
        <ActiveDeal
          onChange={portalToggle}
          id="web-portal"
          disabled={
            merchantDeals.web_portal_status.loading === "loading" ||
            (merchantDeals.pickup_status.status === "service_disabled" &&
              merchantDeals.delivery_status.status === "service_disabled")
          }
          checked={checked("web_portal_status")}
          title="Web portal"
          tooltip={
            merchantDeals.pickup_status.status === "service_disabled" &&
              merchantDeals.delivery_status.status === "service_disabled"
              ? "Just enable any ordering service "
              : undefined
          }
        />
        <ActiveDeal
          onChange={qrPortalToggle}
          id="qr-portal"
          disabled={
            merchantDeals.qr_portal_status.loading === "loading" ||
            (merchantDeals.pickup_status.status === "service_disabled" &&
              merchantDeals.delivery_status.status === "service_disabled")
          }
          checked={checked("qr_portal_status")}
          title="QR portal"
          tooltip={
            merchantDeals.pickup_status.status === "service_disabled" &&
              merchantDeals.delivery_status.status === "service_disabled"
              ? "Just enable any ordering service "
              : undefined
          }
        />
      </div>
    </Modal>
  );
};

export default EditActiveMerchantDeals;
