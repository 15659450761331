import * as React from "react";
import * as styles from "./style.module.sass";
import { ISinglePromoCode } from "../../../../constants";
import moment from "moment";
import { useSelect } from "../../../../hooks/useSelect";
import { GenericButton } from "../../../SharedComponent/GenericButtonWithIcon";
import PromoCodeForm from "../PromoCodeForm";
import { useDispatch } from "react-redux";
import { expirePromoCode } from "../../redux-store/actions/expire-actions";
import { PromoScreens } from "../PromoCodeForm/lib";
import { getPromotionStores } from "../../redux-store/actions/promoCodeActions";
import { ICountry } from "../../../../types";
import { confirmDanger } from "../../BannerCards/lib/confirmDanger";
import { useMemo } from "react";
import ReactTooltip from "react-tooltip";
import Tooltip from "@material-ui/core/Tooltip";
import KOINZ_COUNTRIES from "../../../../statics/koinz-countries";
import copy from "copy-to-clipboard";
import { Popover } from "@material-ui/core";
import { PromoMerchantVisibility, targeting_method_type } from "../../types";
import { change } from "redux-form";
import { useDeepCompareMemo } from "use-deep-compare";
import { allEq } from "../../../../helpers/allEq";
import { oneEq } from "../../../../helpers/oneEq";
import { useCurrency } from "../hooks/useCurrency";

export enum expireType {
  targeted = "targeted",
  universal = "universal",
}
interface IProps {
  promo: ISinglePromoCode;
  countries: ICountry[];
}

const timeRightNow = new Date().getTime();
const SinglePromoCode: React.FC<IProps> = (props) => {
  const { promo, countries } = props;
  const { filters: { brand_ids } } = useSelect(state => state.SystemPromoCodesReducer)

  const refElement = React.useRef(null);

  const {
    discount_value,
    promo_merchant_visibility,
    end_time,
    max_discount_value,
    max_cashback_value,
    cashback_value,
    min_threshold,
    promo_code,
    start_time,
    stores,
    title,
    max_usage_times,
    label,
    type,
    id,
    expired_by_admin_at,
    customers_count,
    customers_file_name,
    customers_file_names,
    rank,
    category,
    
  } = promo;
  const currency = useCurrency(stores[0].country_iso_code)
  // mapping stores promos into names and single country_iso_code
  const storesMinEligibleInvoice = useDeepCompareMemo(() => {
    if (oneEq("min_threshold", stores)) {
      if (allEq("min_threshold", stores)) {
        return typeof stores[0].min_threshold === "number"
          ? `${stores[0].min_threshold}${currency}`
          : "";
      } else {
        return "Multiple";
      }
    } else {
      return typeof min_threshold === "number" ? `${min_threshold}${currency}` : "";
    }
  }, [
    stores,
    min_threshold,
  ]);
  
  const storeMaxDiscountValue = useDeepCompareMemo(() => {
    if (oneEq("max_discount_value", stores)) {
      if (allEq("max_discount_value", stores)) {
        return typeof stores[0].max_discount_value === "number"
          ? `${stores[0].max_discount_value}${currency}`
          : "";
      } else {
        return "Multiple";
      }
    } else {
      return typeof max_discount_value === "number"
        ? `${max_discount_value}${currency}`
        : "";
    }
  }, [
    stores,
    max_discount_value,
  ]);
  const storeMaxCashbackValue = useDeepCompareMemo(() => {
    if (oneEq("max_cashback_value", stores)) {
      if (allEq("max_cashback_value", stores)) {
        return typeof stores[0].max_cashback_value === "number"
          ? `${stores[0].max_cashback_value}${currency}`
          : "";
      } else {
        return "Multiple";
      }
    } else {
      return typeof max_cashback_value === "number"
        ? `${max_cashback_value}${currency}`
        : "";
    }
  }, [
    stores,
    max_cashback_value,
  ]);

  const storesNames = React.useMemo(() => {
    return (Array.isArray(brand_ids) && brand_ids.length > 0 ?
      stores.filter(element => brand_ids.includes(element.id)) :
      stores).map((element) => element.name);
  }, [stores]);

  const start_date = React.useMemo(
    () => moment.unix(start_time / 1000).format("DD MMM YYYY h:mm a"),
    [start_time]
  );
  const end_date = React.useMemo(
    () => moment.unix(end_time / 1000).format("DD MMM YYYY h:mm a"),
    [end_time]
  );
  // country name property depends on the value of the country_iso_code in an element of store
  const country = React.useMemo(
    () =>
      countries.filter(
        (item) =>
          item.ISO_code.toLowerCase() ===
          stores[0].country_iso_code.toLowerCase()
      ),
    [stores]
  );
  const countryIsoCode = React.useMemo(
    () => KOINZ_COUNTRIES[stores[0].country_iso_code.toLowerCase()],
    [stores]
  );
  const dispatch = useDispatch();

  // popover
  const [popover, setPopover] = React.useState(false);
  const handleOpenPopover = () => {
    setPopover(true);
  };
  const handleClosePopover = () => {
    setPopover(false);
  };

  // expire promo code
  const expirePromoCodeHandler = React.useCallback(async () => {
    const result = await confirmDanger(
      "Are you sure you want to expire this promo code?"
    );
    if (result.value) {
      dispatch(expirePromoCode({ promoId: id }));
    }
  }, []);
  // current promotions
  const { currentPromotionStore } = useSelect(
    (state) => state.SystemPromoCodesReducer
  );

  const PromotionStores = React.useMemo(() => {
    return currentPromotionStore.map((element) => element.id);
  }, [currentPromotionStore]);

  // const end_date = React.useMemo(() => new Date(end_time) ,[end_time])
  // edit drawer
  const [openEditDrawer, setOpenEditDrawer] = React.useState(false);
  const handleCloseEditDrawer = () => setOpenEditDrawer(false);
  const handleOpenEditDrawer = () => {
    dispatch(getPromotionStores(id));
    setOpenEditDrawer(true);
    dispatch(
      change(
        "AddPromoCodeForm",
        "stores_ids",
        promo.stores.map((s) => s.id)
      )
    );
    setPromotionStoresIsLoading(true);
    handleClosePopover();
  };
  // reactivation drawer
  const [openReactivateDrawer, setOpenReactivateDrawer] = React.useState(false);
  const handleOpenReactivateDrawer = () => {
    dispatch(getPromotionStores(id));
    setOpenReactivateDrawer(true);
    dispatch(
      change(
        "AddPromoCodeForm",
        "stores_ids",
        promo.stores.map((s) => s.id)
      )
    );
    setPromotionStoresIsLoading(true);
    handleClosePopover();
  };
  const handleCloseReactivateDrawer = () => {
    setOpenReactivateDrawer(false);
  };

  // duplication drawer
  const [
    openPromoDuplicationDrawer,
    setOpenPromoDuplicationDrawer,
  ] = React.useState(false);
  const handleClosePromoDuplicationDrawer = () => {
    setOpenPromoDuplicationDrawer(false);
  };
  const handleOpenPromoDuplicationDrawer = () => {
    setOpenPromoDuplicationDrawer(true);
    dispatch(
      change(
        "AddPromoCodeForm",
        "stores_ids",
        promo.stores.map((s) => s.id)
      )
    );
    dispatch(getPromotionStores(id));
    setPromotionStoresIsLoading(true);
    handleClosePopover();
  };
  // promotion stores is loading
  const [
    promotionStoresIsLoading,
    setPromotionStoresIsLoading,
  ] = React.useState(false);

  React.useEffect(() => {
    if (
      currentPromotionStore.length > 0 &&
      (openEditDrawer || openPromoDuplicationDrawer || openReactivateDrawer)
    ) {
      setPromotionStoresIsLoading(false);
    }
  }, [currentPromotionStore]);

  const isActive = React.useMemo(() => {
    const isBeforeEndTime = timeRightNow < end_time;
    return !expired_by_admin_at && isBeforeEndTime;
  }, [end_time, expired_by_admin_at]);

  const isMultiplier = useMemo(
    () => promo.category === PromoScreens.multiplier,
    [promo.category]
  );
  const goToOtoCampaign = () => {
    window.location.href = `${process.env.OTO_MINI_APP_SITE}/p/${promo.id}`;
  };
  const copyPromoId = React.useCallback(() => {
    copy(id);
  }, [id]);
  const orderingType = {
    pickup: " - Pickup",
    delivery: " - Delivery",
    ordering: " - Pickup, Delivery",
  };
  const customerType = {
    [targeting_method_type.all_customers]: "All users",
    [targeting_method_type.new_customers]: "New users only",
    [targeting_method_type.ordering_customers]: "Ordering users",
  };
  const promocodeVisibility: Record<PromoMerchantVisibility, string> = {
    invisible: "Hidden",
    masked: "Masked",
    visible: "Normal",
  };
  return (
    <div
      data-test-id={`cy-promo-codes-wrapper-${promo.id}`}
      key={promo.id}
      className={`${
        isActive ? styles.itemWrapper : styles.itemWrapperDisabled
      }`}
    >
      {/* {openReactivateModal && (
        <PromocodeReactivation
          open={openReactivateModal}
          onClose={() => setOpenReactivateModal(false)}
          country={country}
          promo={promo}
        />
      )} */}

      <div className={styles.brandWrapper}>
        <Tooltip
          arrow={true}
          classes={{
            tooltip: styles.tooltipTypeWrapper,
          }}
          placement={"top"}
          title={
            label === expireType.universal ? (
              <span style={{ fontSize: "16px" }}>
                {customerType[promo.targeting_method]}
              </span>
            ) : (
              ""
            )
          }
        >
          {isActive ? (
            <div className={styles.avatarWrapper}>
              <img
                src={
                  label === expireType.targeted
                    ? require("../../../../assets/targeted.svg")
                    : require("../../../../assets/uni.svg")
                }
              />
            </div>
          ) : (
            <div className={styles.avatarWrapperDisabled}>
              <img
                src={
                  label === expireType.targeted
                    ? require("../../../../assets/disabled.svg")
                    : require("../../../../assets/uni-disabled.svg")
                }
              />
            </div>
          )}
        </Tooltip>

        <div className={styles.brand}>
          {Array.isArray(storesNames) && (
            <React.Fragment>
              <Tooltip
                arrow={true}
                placement={"bottom"}
                classes={{
                  tooltip: styles.tooltipWrapper,
                }}
                data-test-id={"cy_promo_codes_stores_names_tooltip"}
                title={
                  storesNames.length > 1 ? (
                    <div className={styles.branchNamesTooltipWrapper}>
                      {storesNames
                        .filter((storeName, index) => index > 0)
                        .reduce((prev, curr) => [...prev, ", ", curr], [])
                        .splice(1)
                        .map((storeName, index) => (
                          <span
                            key={index}
                            data-test-id={`cy_promo_codes_stores_names${storeName}`}
                            className={styles.storeNameInTooltip}
                          >
                            {storeName}{" "}
                          </span>
                        ))}
                    </div>
                  ) : (
                    ""
                  )
                }
              >
                <span
                  data-test-id={"cy_promo_codes_store_name"}
                  className={styles.brandName}
                >
                  {`${storesNames[0]?.substring(0, 20)} ${
                    storesNames.length - 1 > 0
                      ? `and ${storesNames.length - 1} other${
                          storesNames.length - 1 > 1 ? "s" : ""
                        }`
                      : ""
                  }`}
                </span>
              </Tooltip>
            </React.Fragment>
          )}
          <span
            data-test-id={"cy_promo_codes_country_codes"}
            className={styles.brandText}
          >
            {country?.[0]?.EnName}
          </span>
        </div>
      </div>
      <div className={styles.promoCodeContent}>
        <div className={styles.copyIdWrapper}>
          <span
            data-test-id={"cy_code_promos_code_promo"}
            className={styles.promoCodeTitle}
            onClick={goToOtoCampaign}
          >
            {promo_code.length > 15
              ? promo_code.trim().substr(0, 15) + "..."
              : promo_code}
          </span>
          <div className={styles.separating} />
          <span
            className={styles.copyIdTitle}
            data-tip={true}
            data-for={`id${id}`}
            onClick={copyPromoId}
          >
            Copy ID
          </span>
          <ReactTooltip
            id={`id${id}`}
            place={"bottom"}
            className={styles.tooltipWrapper}
          >
            <div className={styles.tooltip}>
              <span className={styles.tooltipTitle}>Copy to clipboard</span>
              <span className={styles.tooltipDescription}>{id}</span>
            </div>
          </ReactTooltip>
        </div>
        {promocodeVisibility[
          promo_merchant_visibility as PromoMerchantVisibility
        ] && (
          <span className={styles.maskedPromo}>
            {
              promocodeVisibility[
                promo_merchant_visibility as PromoMerchantVisibility
              ]
            }
          </span>
        )}
        {title && (
          <span
            className={styles.promoCodeDescription}
            onClick={goToOtoCampaign}
          >
            Use code:{" "}
            {title.length > 15 ? title.trim().substr(0, 15) + "..." : title}
          </span>
        )}
      </div>
      <span className={styles.rank}>
        {rank ? `${countryIsoCode}-${rank}` : "-"}
      </span>
      {!isMultiplier && type === PromoScreens.percentage && (
        <div className={styles.item}>
          <div className={styles.headerWrapper}>
            <span className={styles.header}>Percentage</span>
            <span
              data-test-id={"cy_promo_codes_ordering_type"}
              className={styles.orderingType}
            >
              {orderingType[promo.ordering_type]}
            </span>
          </div>
          <span className={styles.text}>
            {discount_value * 100}%
            {storeMaxDiscountValue?.trim()
              ? `| Max: ${storeMaxDiscountValue}`
              : ""}
            `{" "}
            {storesMinEligibleInvoice?.trim()
              ? `| Min: ${storesMinEligibleInvoice}`
              : ""}
          </span>
          <div className={styles.userDetailsWrapper}>
            <div className={styles.usageWrapper}>
              <span className={styles.usageTitle}>Usage:</span>
              <span className={styles.usageDescription}>{max_usage_times}</span>
            </div>
            {customers_count ? (
              <React.Fragment>
                <span className={styles.separator2}>|</span>
                <div className={styles.userWrapper}>
                  <span className={styles.usageTitle}>User:</span>
                  <span
                    data-test-id={"cy_promo_codes_customer_count"}
                    className={styles.usageDescription}
                  >
                    {customers_count}
                  </span>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      )}
      {!isMultiplier &&
        (type === PromoScreens.cashback_percentage ||
          category.includes("cashback")) && (
          <div className={styles.item}>
            <div className={styles.headerWrapper}>
              <span className={styles.header}>Cashback value</span>
              <span
                data-test-id={"cy_promo_codes_ordering_type"}
                className={styles.orderingType}
              >
                {orderingType[promo.ordering_type]}
              </span>
            </div>
            <span className={styles.text}>
              {cashback_value}{currency} | Max:{storeMaxCashbackValue} | Min:{" "}
              {storesMinEligibleInvoice}
            </span>
            <div className={styles.userDetailsWrapper}>
              <div className={styles.usageWrapper}>
                <span className={styles.usageTitle}>Usage:</span>
                <span className={styles.usageDescription}>
                  {max_usage_times}
                </span>
              </div>
              {customers_count ? (
                <React.Fragment>
                  <span className={styles.separator2}>|</span>
                  <div className={styles.userWrapper}>
                    <span className={styles.usageTitle}>User:</span>
                    <span
                      data-test-id={"cy_promo_codes_customer_count"}
                      className={styles.usageDescription}
                    >
                      {customers_count}
                    </span>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        )}
      {!isMultiplier && type === PromoScreens.discount && (
        <div className={styles.item}>
          <div className={styles.headerWrapper}>
            <span className={styles.header}>Discount value</span>
            <span
              data-test-id={"cy_promo_codes_ordering_type"}
              className={styles.orderingType}
            >
              {orderingType[promo.ordering_type]}
            </span>
          </div>
          <span className={styles.text}>
            {discount_value}{currency} | Max:{max_discount_value}{currency} | Min:{" "}
            {min_threshold}{currency}
          </span>
          <div className={styles.userDetailsWrapper}>
            <div className={styles.usageWrapper}>
              <span className={styles.usageTitle}>Usage:</span>
              <span className={styles.usageDescription}>{max_usage_times}</span>
            </div>
            {customers_count ? (
              <React.Fragment>
                <span className={styles.separator2}>|</span>
                <div className={styles.userWrapper}>
                  <span className={styles.usageTitle}>User:</span>
                  <span
                    data-test-id={"cy_promo_codes_customer_count"}
                    className={styles.usageDescription}
                  >
                    {customers_count}
                  </span>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      )}

      {isMultiplier && (
        <div className={styles.item}>
          <div className={styles.headerWrapper}>
            <span className={styles.header}>Points multiplier</span>
            <span
              data-test-id={"cy_promo_codes_ordering_type"}
              className={styles.orderingType}
            >
              {orderingType[promo.ordering_type]}
            </span>
          </div>
          <span className={styles.text}>{promo.multiplier_value}x</span>
          <div className={styles.userDetailsWrapper}>
            <div className={styles.usageWrapper}>
              <span className={styles.usageTitle}>Usage:</span>
              <span className={styles.usageDescription}>{max_usage_times}</span>
            </div>
            {customers_count ? (
              <React.Fragment>
                <span className={styles.separator2}>|</span>
                <div className={styles.userWrapper}>
                  <span className={styles.usageTitle}>User:</span>
                  <span
                    data-test-id={"cy_promo_codes_customer_count"}
                    className={styles.usageDescription}
                  >
                    {customers_count}
                  </span>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      )}

      <div className={styles.dateItem}>
        <div className={styles.dateWrapper}>
          <span data-test-id="cy_promo_codes_date" className={styles.text}>
            {start_date} {">"} {end_date}
          </span>

          {!isActive && <span className={styles.expiredText}>Expired</span>}
          <div className={styles.fileNameWrapper}>
            {customers_file_names?.length ? (
              <React.Fragment>
                <span className={styles.fileNameLabel}> File name</span>
                {customers_file_names?.length ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {customers_file_names
                      .reduce((prev, curr) => [...prev, " , ", curr], [])
                      .splice(1)
                      .map((name, index) => (
                        <span
                          key={index}
                          data-test-id={"cy_promo_codes_customers_file_name"}
                          className={styles.fileNameValue}
                        >
                          {name}
                        </span>
                      ))}
                  </div>
                ) : (
                  <span
                    data-test-id={"cy_promo_codes_customers_file_name"}
                    className={styles.fileNameValue}
                  >
                    {customers_file_name}
                  </span>
                )}
              </React.Fragment>
            ) : null}
          </div>
        </div>
        <div className={popover ? styles.activeBtn : styles.btnWrapper}>
          <Popover
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            anchorEl={refElement.current}
            onClose={handleClosePopover}
            open={popover}
          >
            <div className={styles.popoverWrapper}>
              {isActive && (
                <React.Fragment>
                  <div
                    onClick={handleOpenEditDrawer}
                    className={styles.popoverItemWrapper}
                  >
                    <span className={styles.popoverItem}>Edit promo</span>
                  </div>
                </React.Fragment>
              )}
              <div
                onClick={handleOpenPromoDuplicationDrawer}
                className={styles.popoverItemWrapper}
              >
                <span className={styles.popoverItem}>Duplicate promo code</span>
              </div>
              {!isActive && (
                <div
                  onClick={handleOpenReactivateDrawer}
                  className={styles.popoverItemWrapper}
                >
                  <span className={styles.popoverDeleteItem}>
                    Re-activate promo code
                  </span>
                </div>
              )}
            </div>
          </Popover>
          <div
            className={styles.popoverBtn}
            onClick={handleOpenPopover}
            ref={refElement}
          >
            <img
              className={styles.popoverIcon}
              src={require("../../../../assets/group-2-copy-2.svg")}
            />
          </div>
          {/* {isActive && (
            <GenericButton
              iconPath={require("../../../../assets/edit.svg")}
              className={styles.editBtn}
              IconClass={styles.editIcon}
              onClick={openModalHandler}
            />
          )}{" "} */}
          <div className={styles.separator} />
          {isActive && (
            <GenericButton
              onClick={expirePromoCodeHandler}
              IconClass={styles.expireIcon}
              iconPath={require("../../../../assets/expire.svg")}
              className={styles.expire}
            />
          )}
          {openPromoDuplicationDrawer && (
            <PromoCodeForm
              onSubmitPromoTypes={"duplicate"}
              formLabel={"Duplicate promo code"}
              promotionStoresIsLoading={promotionStoresIsLoading}
              promo_type={promo.label}
              open={openPromoDuplicationDrawer}
              close={handleClosePromoDuplicationDrawer}
              isDuplicating={true}
              isEditing={false}
              ordering_promotion_id={id}
              promo_merchant_visibility={promo.promo_merchant_visibility}
              typeOfPromo={promo.type}
              initialValues={{
                ...promo,
                country_iso_code: country?.[0]?.country_code,
                stores_ids: PromotionStores,
                type: promo.type,
              }}
            />
          )}
          {openReactivateDrawer && (
            <PromoCodeForm
              onSubmitPromoTypes={"reactivate"}
              formLabel={"Reactivate promo code"}
              promotionStoresIsLoading={promotionStoresIsLoading}
              promo_type={promo.label}
              isReactivating={true}
              open={openReactivateDrawer}
              close={handleCloseReactivateDrawer}
              isEditing={true}
              promo_merchant_visibility={promo.promo_merchant_visibility}
              ordering_promotion_id={id}
              typeOfPromo={promo.type}
              initialValues={{
                ...promo,
                country_iso_code: country?.[0]?.country_code,
                stores_ids: PromotionStores,
                type: promo.type,
              }}
            />
          )}
          {openEditDrawer && (
            <PromoCodeForm
              onSubmitPromoTypes={"edit"}
              promotionStoresIsLoading={promotionStoresIsLoading}
              promo_type={promo.label}
              open={openEditDrawer}
              formLabel={"Edit promo code"}
              close={handleCloseEditDrawer}
              isEditing={true}
              ordering_promotion_id={id}
              typeOfPromo={promo.type}
              promo_merchant_visibility={promo.promo_merchant_visibility}
              initialValues={{
                ...promo,
                country_iso_code: country?.[0]?.country_code,
                stores_ids: PromotionStores,
                type: promo.type,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default SinglePromoCode;
