import { useQuery } from "react-query";
import { getActiveMerchantDealsApi } from "../../axios/merchantProfile";
import { useSelect } from "../../hooks/useSelect";
import { ServiceStatus } from "../../atoms/merchantProfile";


export type PortalStatus =
  | ServiceStatus
  | "service_enabled_unpublished"
  | "service_enabled_published";
export interface IStoreActiveDealsResponse {
  pickup_status: ServiceStatus;
  delivery_status: ServiceStatus;
  loyalty_status: ServiceStatus;
  web_portal_status: PortalStatus;
  qr_portal_status: PortalStatus;
}
export const getActiveMerchantDeals = (store_id) => {
  const token = useSelect((state) => state.authReducer.token);
  return useQuery<IStoreActiveDealsResponse, Error>(
    ["store-active-deals", store_id],
    async () => await getActiveMerchantDealsApi(token, store_id),
    {
      enabled: !!store_id,
      cacheTime: 0,
    }
  );
};
