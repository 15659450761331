import {
  IPosEntity,
  LoyaltyPosIntegrationStatus,
  OrderingPosIntegrationStatus,
} from "../../pages/PosIntegration/types";
import { IPosProvider } from "../../axios/getPOSProviders";
import {
  createBrandPosEntityAction,
  updateBrandPosEntityAction,
  getBrandPosEntitiesAction,
  deleteBrandPosEntityAction,
  getPosProvidersAction,
  exportBrandPosMenuAction,
  getBrandPosOrderingIntegrationStatusAction,
  getBrandPosLoyaltyIntegrationStatusAction,
  ACTION_SET_BRAND_POS_ENTITY,
} from "../../constants";
import { action, payload } from "ts-action";
import { LoadingStatus } from "../reducers/withLoadingState";

export const getPosProviders = action(getPosProvidersAction.requested);
export const getPosProvidersSuccess = action(
  getPosProvidersAction.fulfilled,
  payload<IPosProvider[]>()
);
export const getPosProvidersFailure = action(
  getPosProvidersAction.rejected,
  payload<Error>()
);

export const getBrandPosEntities = action(
  getBrandPosEntitiesAction.requested,
  payload<string>()
);
export const getBrandPosEntitiesSuccess = action(
  getBrandPosEntitiesAction.fulfilled,
  payload<IPosEntity[]>()
);
export const getBrandPosEntitiesFailure = action(
  getBrandPosEntitiesAction.rejected,
  payload<Error>()
);
export const getBrandPosEntitiesReset = action(getBrandPosEntitiesAction.reset);

export const createBrandPosEntity = action(
  createBrandPosEntityAction.requested,
  payload<{ store_id: string; pos_vendor: string; name: string }>()
);

export const createBrandPosEntitySuccess = action(
  createBrandPosEntityAction.fulfilled,
  payload<IPosEntity>()
);
export const createBrandPosEntityFailure = action(
  createBrandPosEntityAction.rejected,
  payload<Error>()
);

export const updateBrandPosEntity = action(
  updateBrandPosEntityAction.requested,
  payload<{ id: string; store_id: string; pos_vendor: string; name?: string }>()
);

export const updateBrandPosEntitySuccess = action(
  updateBrandPosEntityAction.fulfilled,
  payload<IPosEntity>()
);
export const updateBrandPosEntityFailure = action(
  updateBrandPosEntityAction.rejected,
  payload<Error>()
);

export const deleteBrandPosEntity = action(
  deleteBrandPosEntityAction.requested,
  payload<{ store_id: string; id: string }>()
);

export const deleteBrandPosEntitySuccess = action(
  deleteBrandPosEntityAction.fulfilled,
  payload<IPosEntity>()
);
export const deleteBrandPosEntityFailure = action(
  deleteBrandPosEntityAction.rejected,
  payload<Error>()
);

export const updatePosEntitiesLoadingStatus = action(
  "UPDATE_POS_ENTITIES_LOADING_STATUS",
  payload<LoadingStatus | undefined>()
);

export const exportBrandPosMenu = action(
  exportBrandPosMenuAction.requested,
  payload<{ pos_entity_id: string; api_key: string }>()
);
export const exportBrandPosMenuSuccess = action(
  exportBrandPosMenuAction.fulfilled,
  payload<{ pos_entity_id: string; JSONFileContent: any }>()
);
export const exportBrandPosMenuFailure = action(
  exportBrandPosMenuAction.rejected,
  payload<{ pos_entity_id: string; error: Error }>()
);
export const exportBrandPosMenuReset = action(
  exportBrandPosMenuAction.reset,
  payload<string>()
);
export const setBrandPosEntity = action(
  ACTION_SET_BRAND_POS_ENTITY,
  payload<IPosEntity | { id: string; mode: undefined }>()
);

export const getBrandPosOrderingIntegrationStatus = action(
  getBrandPosOrderingIntegrationStatusAction.requested,
  payload<{
    store_id: string;
    pos_entity_id: string;
  }>()
);
export const getBrandPosOrderingIntegrationStatusSuccess = action(
  getBrandPosOrderingIntegrationStatusAction.fulfilled,
  payload<{
    status: OrderingPosIntegrationStatus;
    pos_entity_id: string;
  }>()
);
export const getBrandPosOrderingIntegrationStatusFailure = action(
  getBrandPosOrderingIntegrationStatusAction.rejected,
  payload<Error>()
);

export const getBrandPosLoyaltyIntegrationStatus = action(
  getBrandPosLoyaltyIntegrationStatusAction.requested,
  payload<{
    store_id: string;
    pos_entity_id: string;
  }>()
);
export const getBrandPosLoyaltyIntegrationStatusSuccess = action(
  getBrandPosLoyaltyIntegrationStatusAction.fulfilled,
  payload<{
    status: LoyaltyPosIntegrationStatus;
    pos_entity_id: string;
  }>()
);
export const getBrandPosLoyaltyIntegrationStatusFailure = action(
  getBrandPosLoyaltyIntegrationStatusAction.rejected,
  payload<Error>()
);
