import { Mutations } from "../../queries";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeMerchantDealsAtom } from "../../atoms";

export const usePickupDeal = (store_id, data) => {
  const [activeMerchantDeals, setActiveMerchantDeals] = useRecoilState(
    activeMerchantDealsAtom
  );

  const {
    mutate: mutatePickupDeal,
    variables: mutatePickupDealVariables,
    reset: mutationPickupDealReset,
    status: mutatePickupDealStatus,
  } = Mutations.editActiveMerchantDeals(store_id, "pickup-status");
  const pickupToggle = (checked: boolean) => {
    setActiveMerchantDeals((prevState) => ({
      ...prevState,
      pickup_status: {
        status: checked ? "service_enabled" : "service_disabled",
        loading: "loading",
      },
    }));
    mutatePickupDeal(checked ? "service_enabled" : "service_disabled");
  };
  useEffect(() => {
    if (mutatePickupDealStatus === "success") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        pickup_status: {
          status: prevState.pickup_status.status,
          loading: "success",
        },
      }));
      mutationPickupDealReset();
    } else if (mutatePickupDealStatus === "error") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        pickup_status: {
          status: data?.pickup_status,
          loading: "error",
        },
      }));
    }
  }, [mutatePickupDealVariables, mutatePickupDealStatus, data]);

  return {
    pickupToggle,
    activeMerchantDeals,
  };
};
