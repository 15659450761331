import * as React from "react";
import * as styles from "./style.module.sass";
import PromoCodeHeader from "./promoCodeHeader";
import SinglePromoCode from "./singlePromo";
import { useDispatch } from "react-redux";
import { getSystemPromoCode } from "../../../redux-store/actions/getSystemPromoCodesActions";
import { useSelect } from "../../../hooks/useSelect";
import InfiniteScroll from "react-infinite-scroll-component";
import { DotLoader } from "react-spinners";
import { getCountries } from "../../../redux-store/actions/get-countries-actions";
import { TableBodyPlaceholder } from "../components";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import { clearPrevState } from "../redux-store/actions/promoCodeActions";
import { PromocodeFilters } from "./components";

const PromoCode: React.FC = () => {
  const dispatch = useDispatch();
  const { promoById, promos, page, promosIsLoading, filters, promosHasMore } =
    useSelect((state) => state.SystemPromoCodesReducer);
  const getPromos = (pageNum: number) => {
    if (!!promos.length) {
      dispatch(
        getSystemPromoCode({
          page: pageNum,
          per_page: 15,
          filters: {
            ...filters,
          },
        })
      );
    }
  };

  console.log("Loading status:", promosIsLoading);

  const handleNext = () => getPromos(page + 1);

  React.useEffect(() => {
    if (promosIsLoading !== LoadingStatus.loading) {
      dispatch(clearPrevState());
    }
  }, [promosIsLoading]);

  React.useEffect(() => {
    getPromos(0);
    dispatch(getCountries());
  }, []);

  const { countries } = useSelect((state) => state.countriesReducer);
  const ref = React.useRef(null);
  React.useEffect(() => {
    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: undefined,
      })
    );
  }, []);

  return (
    <div style={{ overflowY: "auto" }}>
      <PromocodeFilters />
      <PromoCodeHeader totalPromos={promos.length} />
      <InfiniteScroll
        dataLength={promos.length}
        next={handleNext}
        hasMore={promosHasMore}
        style={{ overflowY: "hidden" }}
        loader={
          <div ref={ref} className={styles.loader}>
            <DotLoader color={"#f09440"} />
          </div>
        }
      >
        {promos.length ? (
          <React.Fragment>
            {promos.map((item, index) => (
              <SinglePromoCode
                key={promoById[item].id}
                promo={promoById[item]}
                countries={countries}
              />
            ))}
          </React.Fragment>
        ) : (
          !promosHasMore &&
          promosIsLoading !== LoadingStatus.loading && (
            <TableBodyPlaceholder label={"No promo codes have been found"} />
          )
        )}
      </InfiniteScroll>
    </div>
  );
};

export default PromoCode;
