import * as Requests from "../requests";
import axios from "axios";

export type ServiceType =
  | "delivery-status"
  | "pickup-status"
  | "web-portal-status"
  | "loyalty-status"
  | "qr-portal-status";
export const editActiveMerchantDealsApi = (
  token,
  store_id,
  service_type: ServiceType,
  status: "service_enabled" | "service_disabled"
) => {
  return axios.put(
    service_type === "qr-portal-status"
      ? Requests.editOrderingQRPortalStatusURL(store_id)
      : service_type === "web-portal-status"
      ? Requests.editOrderingPortalStatusURL(store_id)
      : Requests.editActiveMerchantDealsURL(store_id, service_type),
    service_type === "web-portal-status"
      ? { admin_web_portal_status: status }
      : { status },
    {
      headers: { token },
      baseURL: ["web-portal-status", "qr-portal-status"].includes(service_type)
        ? process.env.PICKUP_ENDPOINT
        : process.env.LUMEN_ENDPOINT,
    }
  );
};
