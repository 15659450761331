import { ISingleCountryStore } from "../../axios/getBrands";
import * as constants from "../../constants";
import { getCountriesActionTypes } from "../../constants";

interface ICountryStore {
  stores: ISingleCountryStore[];
  is_loading: boolean;
  error: string;
}
const InitialState: ICountryStore = {
  stores: [],
  is_loading: false,
  error: "",
};

const CountryStoresReducer = (
  state = InitialState,
  action: getCountriesActionTypes
): ICountryStore => {
  switch (action.type) {
    case constants.getCountryStoresAction.requested:
      return {
        stores: [...state.stores],
        is_loading: true,
        error: "",
      };
    case constants.getCountryStoresAction.fulfilled:
      return {
        stores: action.payload as ISingleCountryStore[],
        is_loading: false,
        error: "",
      };
    case constants.getCountryStoresAction.rejected:
      return {
        error: action.payload as any,
        is_loading: false,
        stores: [...state.stores],
      };
    default:
      return state;
  }
};

export default CountryStoresReducer;
