import {
  Field,
  reduxForm,
  InjectedFormProps,
  formValueSelector,
} from "redux-form";
import { Drawer } from "@material-ui/core";
import React from "react";
import * as styles from "./styles.module.sass";
import { required } from "../../../../redux-store/validators";
import { ISelectItemData } from "../../../FormElements/RenderDropDownSelect";
import { SystemAnnouncementTargeting } from "../../types";
import { useDispatch } from "react-redux";
import { createAnnouncement } from "../../redux-store/actions/announcementsActions";
import { useSelect } from "../../../../hooks/useSelect";
import { RenderGenericFileUploadField } from "../../BannerCards/AddBannerCard/RenderGenericFileUploadField";
import { RenderMultipleRadioButtonsField } from "../../BannerCards/AddBannerCard/RenderMultipleRadioButtonsField";
import { CustomDropZoneField } from "../../BannerCards/AddBannerCard/CustomDropZoneField";
import {
  isScreenRequiresStoreId,
  TARGET_SCREENS,
} from "../../BannerCards/lib/mobile-app-screens";
import { SelectStoresByCountry } from "../../AddPromoCode/PromoCodeForm/SelectStoresByCountry";
import { getStoreMetaData, maxLength1 } from "../../BannerCards/AddBannerCard";
import { RenderDropDownSelect } from "../../../SharedComponent/RenderDropDownSelect";
import { RenderGenericField } from "../../BannerCards/AddBannerCard/RenderGenericField";
interface IExtraProps {
  open: boolean;
  closeForm: () => void;
}

const VisibilityOptions: ISelectItemData<SystemAnnouncementTargeting>[] = [
  {
    value: SystemAnnouncementTargeting.public,
    label: "All users",
  },
  {
    value: SystemAnnouncementTargeting.private,
    label: "Selected users",
  },
];

const addAnnouncementStateSelector = formValueSelector("addAnnouncement");

type IProps = IExtraProps & InjectedFormProps;

const AddAnnouncement: React.FC<IProps> = ({
  open,
  closeForm,
  handleSubmit,
  invalid,
  destroy,
  form,
}) => {
  const dispatch = useDispatch();
  const submitCard = React.useCallback((values) => {
    const routing_meta_data = getStoreMetaData(values);
    dispatch(
      createAnnouncement({
        ...values,
        routing_meta_data,
      })
    );

    handleClose();
  }, []);
  const handleClose = React.useCallback(() => {
    destroy();
    closeForm();
  }, [closeForm, destroy]);
  const { visibility, routing_value } = useSelect((state) =>
    addAnnouncementStateSelector(state, "visibility", "routing_value")
  );
  return (
    <Drawer anchor={"right"} open={open}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Add popup announcement
          <span className={"icon-close"} onClick={handleClose} />
        </div>
        <Field
          name="image"
          wrapperClassName={styles.imageUploadWrapper}
          imagePreviewClassName={styles.imagePreviewWrapper}
          dropZoneClassName={styles.dropZoneWrapper}
          imageSize={"1200px * 312px"}
          component={CustomDropZoneField}
          validate={[required]}
        />
        <Field
          validate={[required]}
          name={"routing_value"}
          label={"Target screen"}
          options={TARGET_SCREENS}
          className={true}
          component={RenderDropDownSelect as any}
        />

        {isScreenRequiresStoreId(routing_value) && (
          <SelectStoresByCountry
            validateCountry={[required]}
            validateStores={[required, maxLength1]}
            onlyOne={true}
            formName={form}
          />
        )}
        <Field
          component={RenderGenericField}
          optional={true}
          label={"Target promo code ID"}
          name={"ordering_promotion_id"}
        />
        <Field
          validate={[required]}
          label={"Target users"}
          component={RenderMultipleRadioButtonsField}
          options={VisibilityOptions}
          name="visibility"
        />
        {visibility === SystemAnnouncementTargeting.private && (
          <Field
            validate={[required]}
            buttonText={"Upload CSV file"}
            label={"CSV upload"}
            component={RenderGenericFileUploadField}
            name={"customers_file"}
          />
        )}
        <button
          disabled={invalid}
          onClick={handleSubmit(submitCard)}
          className={styles.submit}
        >
          Add popup
        </button>
      </div>
    </Drawer>
  );
};

export default reduxForm<{}, IExtraProps>({
  form: "addAnnouncement",
})(AddAnnouncement as React.FC<IExtraProps>);
