import {
  UseFormSetError,
  FieldValues,
  UseFormClearErrors,
} from "react-hook-form";
import { ISingleCountryStore } from "../../axios/getBrands";
import { ICountry } from "../../types";

export enum SystemAnnouncementType {
  screen = "screen",
  url = "url",
}

export enum SystemAnnouncementTargeting {
  public = "public",
  private = "private",
}

export interface IBannerCardBase {
  type: SystemAnnouncementType;
  visibility: SystemAnnouncementTargeting;
  target_users: number;
  value: string;
  meta_data?: any;
  id: string;
  ordering_promotion_id?: string;
  image: string | File;
}

export interface IGetBannerCardItem extends IBannerCardBase {
  order: number;
}

export interface IReorderBannerCardItem extends IBannerCardBase {
  order: number;
}

export interface ICreateBannerCard extends IBannerCardBase {
  image: File;
  customers_file?: File;
  stores_ids?: string[];
}

export interface IAnnouncementBase {
  routing_type: SystemAnnouncementType;
  routing_value: string;
  routing_meta_data?: string;
  visibility: SystemAnnouncementTargeting;
  target_users: number;
  id: string;
  image: string | File;
  start_time: number;
  end_time: number;
  ordering_promotion_id?: string;
}

export interface ICreateAnnouncement extends IAnnouncementBase {
  image: File;
  customers_file?: File;
}

export interface ICategory {
  id: string;
  inactive_image: string | File;
  active_image: string | File;
  name_ar: string;
  name_en: string;
}

export interface ICreateCategory {
  id: string;
  name_ar: string;
  name_en: string;
  active_image: File | string;
  inactive_image: File | string;
}

export interface IEditPromoCode {
  stores_ids: string[];
  end_time: number;
  title: string;
  max_usage_times: number;
  desc: string;
  min_threshold: number;
  ar_desc?: string;
  ar_title?: string;
  max_discount_value: number;
  promo_merchant_visibility?: PromoMerchantVisibility;
  allowed_payment_method?: AllowedPaymentMethod;
}

export enum targeting_method_type {
  all_customers = "all_customers",
  new_customers = "new_customers",
  ordering_customers = "ordering_customers",
}

export enum PromoMerchantVisibility {
  invisible = "invisible",
  visible = "visible",
  masked = "masked",
}

export enum AllowedPaymentMethod {
  all = "all",
  online = "online",
}
export interface IPromCode {
  stores_ids: string[];
  title: string;
  desc: string;
  type: string;
  min_threshold: number;
  discount_value: number;
  ar_title: string;
  ar_desc: string;
  max_discount_value: number;
  start_time: number;
  end_time: number;
  max_usage_times: number;
  multiplier_value: string;
  promo_code: string;
  promo_merchant_visibility?: PromoMerchantVisibility;
  allowed_payment_method?: AllowedPaymentMethod;
  is_exclusive: 0 | 1;
}

export interface ICreatePromoCode extends IPromCode {
  target_customers: File;
}
export interface ICreateUniversalPromoCode extends IPromCode {
  targeting_method: targeting_method_type;
}

export interface IPromotionStores {
  id: string;
  name: string;
}

export const enum PromocodeStatus {
  active = "active",
  inactive = "inactive",
}
export namespace PromocodeInterfaces {
  export interface IPromocodeFilter {
    status?: PromocodeStatus | undefined;
    country_codes?: string[];
    brand_ids?: string[];
    order_by?: string[];
    search?: string;
  }
  export interface IPromocodeByRank {
    category: "points_multiplier" | "discount";
    end_time: number;
    discount_value: number;
    created_at: number;
    promo_code: string;
    type: string;
    title: string;
    max_discount_value: number;
    max_usage_times: number;
    min_threshold: number;
    start_time: number;
    updated_at: number;
    user_id: string;
    multiplier_value: string;
    id: string;
    desc: string;
    ar_title: string;
    ar_desc: string;
    stores_fund_percentage: any;
    expired_at: string;
    label: string;
    expired_by_admin_at?: number;
    ordering_type: string;
    targeting_method: string;
    suggested: number;
    customers_file_path: string;
    promo_merchant_visibility?: PromoMerchantVisibility;
    allowed_payment_method?: AllowedPaymentMethod;
    is_compensational: boolean;
    customers_file_name: string;
    customers_file_names: string[];
    customers_count: number;
    rank: number;
  }
}

export type ActionTypes = "edit" | "create" | "reactivate" | "duplicate";

export enum PromoTypes {
  default = "",
  percentage = "discount_percentage",
  discount = "discount_value",
  multiplier = "points_multiplier",
  cashback_percentage = "cashback_percentage",
}

export enum OrderingTypes {
  ordering = "ordering",
  pickup = "pickup",
  delivery = "delivery",
}

export enum ToggleStatus {
  enable = "enable",
  disable = "disable",
}

export enum PromocodeTarget {
  normal = "Normal",
  universal = "Universal",
  compensational = "Compensational",
}
export interface IMutationArguments {
  values: any;
  initialValues: any;
  promo_id?: string;
  toggle_users?: ToggleStatus;
  country_iso_code?: string;
  promocode_target?: PromocodeTarget;
}

export interface IUsePromocodeForm {
  country_iso_code: string;
  countryStores: any;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  isDuplicating: boolean;
  isReactivating: boolean;
  isEditing: boolean;
  initialValues: any;
  stores_ids: string[];
  watchers: any;
  masked: boolean;
  reset: (
    values?: any,
    keepStateOptions?:
      | Partial<{
          keepErrors: boolean;
          keepDirty: boolean;
          keepValues: boolean;
          keepDefaultValues: boolean;
          keepIsSubmitted: boolean;
          keepTouched: boolean;
          keepIsValid: boolean;
          keepSubmitCount: boolean;
        }>
      | undefined
  ) => void;
  close: () => void;
  setValue: (
    name: string,
    value: any,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
}

export interface IPromocodeForm {
  open: boolean;
  close: () => void;
  countries: ICountry[];
  onSubmitPromoTypes: ActionTypes;
  country_iso_code: string;
  valid_promo_code: boolean;
  isDuplicating?: boolean;
  isEditing?: boolean;
  isReactivating?: boolean;
  formLabel?: string;
  promo_code: string;
  stores_ids: any;
  start_time: Date;
  typeOfPromo: string;
  end_date: Date;
  promo_type?: string;
  viewOnly?: boolean;
  target_customers: File;
  promo_merchant_visibility?: PromoMerchantVisibility;
  allowed_payment_method?: AllowedPaymentMethod;
  stores_fund_percentage?: any;
  toggle_users: ToggleStatus;
  ordering_promotion_id: string;
  suggested: ToggleStatus;
  countryStores: ISingleCountryStore[];
  applySplitForAllParameterValue: {
    koinz: number;
    brand: number;
  };
  promotionStoresIsLoading: boolean;
}
export type ResetReactHookForm = (
  values?: any,
  keepStateOptions?:
    | Partial<{
        keepErrors: boolean;
        keepDirty: boolean;
        keepValues: boolean;
        keepDefaultValues: boolean;
        keepIsSubmitted: boolean;
        keepTouched: boolean;
        keepIsValid: boolean;
        keepSubmitCount: boolean;
      }>
    | undefined
) => void;
export type SetReactHookFormValue = (
  name: string,
  value: any,
  options?:
    | Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
        shouldTouch: boolean;
      }>
    | undefined
) => void;
