import { on, reducer } from "ts-action";
import { IAnnouncementBase } from "../../types";
import {
  createAnnouncement,
  createAnnouncementSuccess,
  deleteAnnouncement,
  getAnnouncementsSuccess,
} from "../actions/announcementsActions";
import { mapById, convertToIds } from "./lib";
import {
  ILoadingState,
  withLoadingReducer,
} from "../../../../redux-store/reducers/withLoadingState";
import { getAnnouncementsAction } from "../actions/constants";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";

interface IState {
  announcements: string[];
  announcementsById: { [x: string]: IAnnouncementBase };
}

export const announcementsReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(getAnnouncementsSuccess, (state, { payload }) => ({
          ...state,
          announcements: convertToIds(payload),
          announcementsById: mapById(payload),
        })),
        on(createAnnouncement, (state, action) => {
          const id = action.meta.optimistic.id.toString();
          return {
            ...state,
            announcements: [id, ...state.announcements],
            announcementsById: {
              ...state.announcementsById,
              [id]: action.payload,
            },
          };
        }),
        on(deleteAnnouncement, (state, action) => {
          return {
            ...state,
            announcements: state.announcements.filter(
              (item) => item !== action.payload.id
            ),
          };
        }),
        on(createAnnouncementSuccess, (state, { payload, meta }) => {
          const optId = meta.optimistic.id.toString();
          return {
            ...state,
            announcementsById: {
              ...state.announcementsById,
              [payload.id]: payload,
            },
            announcements: state.announcements.map((id) =>
              optId === id ? payload.id : id
            ),
          };
        }),
      ],
      { announcements: [], announcementsById: {} }
    ),
    getAnnouncementsAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
