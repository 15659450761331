import { action, payload } from "ts-action";
import {
  createAnnouncementAction,
  deleteAnnouncementAction,
  getAnnouncementsAction,
} from "./constants";
import { ICreateAnnouncement, IAnnouncementBase } from "../../types";
import { optAction, optReaction } from "../../../../redux-store/actions/lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const getAnnouncements = action(getAnnouncementsAction.requested);
export const getAnnouncementsSuccess = action(
  getAnnouncementsAction.fulfilled,
  payload<IAnnouncementBase[]>()
);
export const getAnnouncementsFailure = action(getAnnouncementsAction.rejected);

export const createAnnouncement = optAction(
  createAnnouncementAction.requested,
  payload<ICreateAnnouncement>()
);
export const createAnnouncementSuccess = optReaction(
  createAnnouncementAction.fulfilled,
  payload<IAnnouncementBase>(),
  COMMIT
);
export const createAnnouncementFailure = optReaction(
  createAnnouncementAction.rejected,
  payload<ICreateAnnouncement>(),
  REVERT
);

export const deleteAnnouncement = optAction(
  deleteAnnouncementAction.requested,
  payload<IAnnouncementBase>()
);
export const deleteAnnouncementSuccess = optReaction(
  deleteAnnouncementAction.fulfilled,
  payload<IAnnouncementBase>(),
  COMMIT
);
export const deleteAnnouncementFailure = optReaction(
  deleteAnnouncementAction.rejected,
  payload<IAnnouncementBase>(),
  REVERT
);
